import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly2A2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/events-activities/class-assembly/2A/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/class-assembly/2A/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/class-assembly/2A/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/class-assembly/2A/4.webp`;




  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },

  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Staying Healthy
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 2 A Date: 19 and 20 September 2024

        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  “Health is the greatest wealth.”
<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 2A presented their assembly on the topic ‘Staying Healthy’ on Tuesday 19
November and Wednesday 20 November 2024.The aim was to emphasize the importance of
leading a healthy lifestyle for overall well-being.
 <br></br>
 The topic was introduced with a thought for the day related to Staying Healthy, followed by an
engaging skit performed by the students. The skit creatively showcased the benefits of eating
nutritious food, exercising regularly, and maintaining good hygiene.
 <br></br>
 On Day 2, the assembly began with another insightful thought, leading into an energetic and
uplifting dance performance on a song promoting health and wellness, leaving the audience
motivated and cheerful.
  <br></br>
  Overall, the assembly helped the students understand the importance of staying healthy and
encouraged them to adopt healthy habits in their daily lives.
  <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “A healthy mind lives in a healthy body.”
   <br></br>
  
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly2A2024;